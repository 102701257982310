<div class="grid">
  <div [ngClass]="enableProductCatalog ? 'col-8' : 'col-12'">
    <h4 class="text-primary">{{'COLLI' | translate}}</h4>
    <form [formGroup]="colliForm" (keydown.enter)="onEnter()">
      <div class="p-fluid p-formgrid grid">
        <div class="col-6 ">
          <p-inputNumber formControlName="quantity" #quantity type="number" id="quantity" [min]="1" [max]="1000"
            name="quantityField" [useGrouping]="false" placeholder="{{'AMOUNT' | translate}}"></p-inputNumber>
        </div>

        <div class="col-6">
          <span class="p-float-label">
            <p-dropdown inputId="collitypes" [options]="agreements" formControlName="type"
              placeholder="{{'COLLITYPE' | translate}}" [optionLabel]="formattedColliName">
            </p-dropdown>
            <label for="collitypes">{{'COLLITYPE' | translate}}</label>
          </span>
        </div>

        <div class="col-4" *ngIf="isRequiredField('length')">
          <span class="p-float-label">
            <p-inputNumber inputId="colliLength" mode="decimal" [minFractionDigits]="2" locale="da-DK"
              pTooltip="{{'LENGTHINMETERS' | translate}}" tooltipPosition="top" [min]="0.01"
              formControlName="length"></p-inputNumber>
            <label for="colliLength">{{'GENERIC.LENGTH' | translate}}(m)</label>
            <div *ngIf="colliForm['controls']['length'].errors?.max">
              <p class="error">{{'COLLIFORM.VALIDATION.MAXLIMIT' | translate: {limit: 20} }}</p>
            </div>
          </span>
        </div>

        <div class="col-4" *ngIf="isRequiredField('width')">
          <span class="p-float-label">
            <p-inputNumber inputId="colliWidth" formControlName="width" mode="decimal" [minFractionDigits]="2"
              pTooltip="{{'WIDTHINMETERS' | translate}}" tooltipPosition="top" [min]="0.01"
              locale="da-DK"></p-inputNumber>
            <label for="colliWidth">{{'GENERIC.WIDTH' | translate}}(m)</label>
          </span>
          <div *ngIf="colliForm['controls']['width'].errors?.max">
            <p class="error">{{'COLLIFORM.VALIDATION.MAXLIMIT' | translate: {limit: 4} }}</p>
          </div>
        </div>

        <div class="col-4" *ngIf="isRequiredField('height')">
          <span class="p-float-label">
            <p-inputNumber inputId="colliHeight" formControlName="height" mode="decimal" [minFractionDigits]="2"
              pTooltip="{{'HEIGHTINMETERS' | translate}}" tooltipPosition="top" [min]="0.01"
              (focusout)="focusHeightLost()" locale="da-DK"></p-inputNumber>
            <label for="colliHeight">{{'GENERIC.HEIGHT' | translate}}(m)</label>
          </span>
          <div *ngIf="colliForm['controls']['height'].errors?.max">
            <p class="error">{{'COLLIFORM.VALIDATION.MAXLIMIT' | translate: {limit: 4} }}</p>
          </div>
        </div>

        <div class="col-4" *ngIf="isRequiredField('volume')">
          <span class="p-float-label">
            <p-inputNumber inputId="colliVolume" formControlName="volume" mode="decimal" [minFractionDigits]="2"
              [min]="0.01" locale="da-DK"></p-inputNumber>
            <label for="colliVolume">{{'GENERIC.VOLUME' | translate}}</label>
          </span>
        </div>

        <div class="col-4" *ngIf="isRequiredField('loadMeter')">
          <span class="p-float-label">
            <p-inputNumber inputId="loadMeter" formControlName="loadMeter" mode="decimal" [minFractionDigits]="2"
              [min]="0.01" locale="da-DK"></p-inputNumber>
            <label for="loadMeter">{{'LDM' | translate}}</label>
          </span>
        </div>

        <div class="col-4" *ngIf="isRequiredField('weight')">
          <span class="p-float-label">
            <p-inputNumber inputId="weight" formControlName="weight" type="number" #weight [min]="0.01"
              pTooltip="{{'COLLIFORM.VALIDATION.WEIGHTISINKG' | translate}}" tooltipPosition="top"
              [useGrouping]="false"></p-inputNumber>
            <label for="weight">{{'COLLIWEIGHTMESSAGE' | translate}}</label>
          </span>
        </div>

        <div class="col-12">
          <span class="p-float-label">
            <textarea [rows]="1" id="description" pInputTextarea formControlName="description" type="text"
              [autoResize]="true"></textarea>
            <label for="description">{{'COLLIDESCRIPTION' | translate}}</label>
          </span>
        </div>

        <div class="col-12" *ngIf="!removeBtns">
          <button pButton pRipple type="button" class="p-button-success" (click)="onEnter()" tooltipEvent="focus"
            [disabled]="!colliForm.valid" pTooltip="{{'COLLIFORM.ENTERKEYTOSUBMIT' | translate}}"
            label="{{'GENERIC.ADD' | translate}}"></button>
        </div>

      </div>
    </form>
  </div>

  <div class="col-4" *ngIf="enableProductCatalog">
    <h4 class="text-primary">{{'HEADERS.PRODUCTCATALOG' | translate}}</h4>
    <p-table [value]="products" [globalFilterFields]="['name', 'sku']" [paginator]="true" [rows]="5" #productdt>
      <ng-template pTemplate="caption">
        <div class="flex">
          <input pInputText type="text" (input)="productdt.filterGlobal($event.target.value, 'contains')"
            placeholder="{{'GENERIC.SEARCH' | translate}}" />
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th class="w-5">{{'PRODUCTCATALOG.TABLE.NAME' | translate}}</th>
          <th class="w-5">{{'PRODUCTCATALOG.TABLE.SKU' | translate}}</th>
          <th class="w-auto"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td class="w-5">{{product.name}}</td>
          <td class="w-5">{{product.sku}}</td>
          <td class="w-auto"><button pButton (click)="applyProduct(product)" icon="pi pi-plus"
              class="p-button-rounded"></button>
          </td>
        </tr>
      </ng-template>

    </p-table>
  </div>
</div>
<div class="grid">
  <div class="col-12">
    <p-divider></p-divider>

    <app-form-error [controls]="colliForm.controls"></app-form-error>

    <div class="bottomBar" *ngIf="!removeBtns">
      <button pButton pRipple label="{{'GENERIC.PREVIOUS' | translate}}" *ngIf="stepperService.activeIndex > 0"
        (click)="stepperService.stepPrev()"></button>
      <button id="nextBtn" #nextBtn pButton pRipple label="{{'GENERIC.NEXT' | translate}}" [disabled]="checkNextBtn()"
        (focus)="stepperService.stepNext();addColli()" (click)="stepperService.stepNext();addColli()"></button>
    </div>

  </div>
</div>