import { Component, OnInit } from '@angular/core';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { Subscription } from 'rxjs';
import { AppConfig } from './domain/appconfig';
import { ConfigService } from './services/app.config.service';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from './services/client.service';
@Component({
    selector: 'app-config',
    template: `
        <a style="cursor: pointer" id="layout-config-button" class="layout-config-button" (click)="onConfigButtonClick($event)">
            <i class="pi pi-cog"></i>
        </a>
        <div class="layout-config" [ngClass]="{'layout-config-active': appMain.configActive}" (click)="appMain.onConfigClick($event)">
            <h5 style="text-align:center;">{{'COLORSCHEME' | translate}}</h5>
            <div class="field-radiobutton">
                <p-radioButton name="darkMode" value="light" [(ngModel)]="app.colorScheme" inputId="darkMode1"
                               (onClick)="changeColorScheme('light')"></p-radioButton>
                <label for="darkMode1">{{'LIGHT' | translate}}</label>
            </div>
            <div class="field-radiobutton">
                <p-radioButton name="darkMode" value="dark" [(ngModel)]="app.colorScheme" inputId="darkMode2"
                               (onClick)="changeColorScheme('dark')"></p-radioButton>
                <label for="darkMode2">{{'DARK' | translate}}</label>
            </div>
            <h5 style="text-align:center;">{{'THEME' | translate}}</h5>
            <div class="layout-themes">
                <div *ngFor="let t of themeColors">
                    <a style="cursor: pointer" (click)="changeLayout(t.name)" [ngStyle]="{'background-color': t.color}">
                        <i *ngIf="app.theme === t.name" class="pi pi-check"></i>
                    </a>
                </div>
            </div>
            <h5 style="text-align:center;">{{'LANGUAGES' | translate}}</h5>
            <div class="layout-themes" style="flex-direction:column">
                <div *ngFor="let t of languages">
                    <div class="field-radiobutton">
                        <p-radioButton [value]="t.lang" [(ngModel)]="chosenLanguage" inputId="{{t.lang}}"
                               (onClick)="changeLanguage(t.lang)"></p-radioButton>
                        <label for="{{t.lang}}">{{t.name}}</label>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class AppConfigComponent implements OnInit {

    layoutColors: any[];

    themeColors: any[];

    languages: any[];

    chosenLanguage: any;

    config: AppConfig;

    subscription: Subscription;

    constructor(public appMain: AppMainComponent, public app: AppComponent, public configService: ConfigService, public translate: TranslateService, public clientService: ClientService) { }

    ngOnInit() {
        this.subscription = this.configService.configUpdate$.subscribe(config => {
            this.config = config;
        });

        this.languages = [
            {
                lang: 'en',
                name: 'English'
            },
            {
                lang: 'da',
                name: 'Dansk'
            },
            {
                lang: 'de',
                name: 'Deutsch'
            },
            {
                lang: 'se',
                name: 'Svenska'
            },
            {
                lang: 'nl-NL',
                name: 'Nederlands'
            },
            {
                lang: 'fi',
                name: 'Suomi'
            }];
        if (localStorage.getItem('language')) {
            this.chosenLanguage = localStorage.getItem('language');
        }
        else {
            this.chosenLanguage = navigator.language == 'en-US' ? 'en' : navigator.language;
        }

        if (localStorage.getItem('colorScheme')) {
            this.app.colorScheme = localStorage.getItem('colorScheme');
        }
        else {
            this.app.colorScheme = "light"; // if none default to light
        }

        var themeColor = "red";
        
        if(this.clientService.clientTenant == 'Local dev environment' ||
            this.clientService.clientTenant == 'Johannes Andersen'
        ){
            themeColor='blue';
        }
        if (localStorage.getItem('themeColor')) {
            themeColor = localStorage.getItem('themeColor');
        }
        this.changeLayout(themeColor);

        this.themeColors = [
            { name: 'blue', color: '#0F8BFD' },
            { name: 'green', color: '#0BD18A' },
            { name: 'magenta', color: '#EC4DBC' },
            { name: 'orange', color: '#FD9214' },
            { name: 'purple', color: '#873EFE' },
            { name: 'red', color: '#FC6161' },
            { name: 'teal', color: '#00D0DE' },
            { name: 'yellow', color: '#EEE500' }
        ];

        this.layoutColors = [
            { name: 'blue', color: '#0F8BFD' },
            { name: 'green', color: '#0BD18A' },
            { name: 'magenta', color: '#EC4DBC' },
            { name: 'orange', color: '#FD9214' },
            { name: 'purple', color: '#873EFE' },
            { name: 'red', color: '#FC6161' },
            { name: 'teal', color: '#00D0DE' },
            { name: 'yellow', color: '#EEE500' }
        ];
    }

    changeLanguage(lang: any) {
        this.translate.use(lang);
        this.chosenLanguage = lang;
        localStorage.setItem('language', lang);
    }

    changeColorScheme(scheme) { // Light / Dark Mode
        localStorage.setItem('colorScheme', scheme);
        this.changeStyleSheetsColor('layout-css', 'layout-' + scheme + '.css', 1);
        this.changeStyleSheetsColor('theme-css', 'theme-' + scheme + '.css', 1);

        this.app.colorScheme = scheme;
        this.configService.updateConfig({ ...this.config, ...{ dark: scheme === 'dark' } });
    }

    changeStyleSheetsColor(id, value, from) { // Switches styling on light/dark mode
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');

        if (from === 1) {           // which function invoked this function - change scheme
            urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {       // which function invoked this function - change color
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join('/');

        this.replaceLink(element, newURL);
    }


    changeLayout(layout) {
        localStorage.setItem('themeColor', layout);
        const layoutLink: HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement;
        const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
        const hreflayout = 'assets/layout/css/' + layout + '/layout-' + this.app.colorScheme + '.css';
        const hreftheme = 'assets/theme/' + layout + '/theme-' + this.app.colorScheme + '.css';

        this.app.layout = layout;
        this.app.theme = layout;

        this.replaceLink(layoutLink, hreflayout);
        this.replaceLink(themeLink, hreftheme);
    }

    isIE() {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    }

    replaceLink(linkElement, href) {
        if (this.isIE()) {
            linkElement.setAttribute('href', href);
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    onConfigButtonClick(event) {
        this.appMain.configActive = !this.appMain.configActive;
        this.appMain.configClick = true;
        event.preventDefault();
    }
}
