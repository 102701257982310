import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SimpleShipment } from '../domain/SimpleShipment';
import { Shipment } from '../domain/Shipment';
import { formatDate } from '@angular/common';
import { SignalrService } from './signalr.service';
import { HubConnection } from '@microsoft/signalr';
import { BehaviorSubject, Observable, Subject, switchMap, throttleTime } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShipmentService {
  hubConnection: HubConnection;

  private todaysShipmentsCache = new BehaviorSubject<SimpleShipment[]>([]);
  private refreshTodaysShipments = new Subject<void>();
  private notSubmittedShipmentsCache = new BehaviorSubject<SimpleShipment[]>([]);
  private refreshNotSubmittedShipments = new Subject<void>();

  constructor(private httpClient: HttpClient, private signalr: SignalrService) {
    this.ensureHubConnection();

    this.hubConnection.onreconnected(() => {
      this.forceRefreshShipmentsCache();
    });

    this.fetchTodaysShipments().subscribe((x) => this.todaysShipmentsCache.next(x));
    this.fetchNotSubmittedShipments().subscribe((x) => this.notSubmittedShipmentsCache.next(x));

    this.setupTodaysShipmentsSignalR();
    this.setupNotSubmittedShipmentsSignalR();

    this.refreshTodaysShipments
      .pipe(
        throttleTime(3000),
        switchMap(() => this.fetchTodaysShipments())
      )
      .subscribe((shipments) => {
        this.todaysShipmentsCache.next(shipments);
      });
    this.refreshNotSubmittedShipments
      .pipe(
        throttleTime(3000),
        switchMap(() => this.fetchNotSubmittedShipments())
      )
      .subscribe((shipments) => {
        this.notSubmittedShipmentsCache.next(shipments);
      });
  }

  private ensureHubConnection(): HubConnection {
    if (!this.hubConnection || this.hubConnection.state !== 'Connected') {
      if (this.hubConnection && this.hubConnection.state === 'Connecting') {
        return this.hubConnection;
      }
      this.hubConnection = this.signalr.CreateConnection('shipment');
      this.hubConnection
        .start()
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
    }
    return this.hubConnection;
  }

  updateShipmentStatus(shipmentId: any, status: any, eventDate: any) {
    let updateCommand = {
      shipmentId: shipmentId,
      newStatus: status,
      eventDate: eventDate,
    };
    return this.httpClient.post(`${environment.apiUrl}/shipments/updateStatus`, updateCommand);
  }

  updateShipmentDetails(shipment: Shipment) {
    let updateCommand = {
      updateShipmentDetails: {
        shipmentId: shipment.id,
        reference1: shipment.reference1,
        reference2: shipment.reference2,
        reference3: shipment.reference3,
        note: shipment.note,
        driverNote: shipment.driverNote,
      },
    };
    return this.httpClient.post(`${environment.apiUrl}/shipments/updateDetails`, updateCommand);
  }

  updateShipmentExchangePallets(shipment: any) {
    let updateCommand = {
      updateShipmentExchangePallets: {
        shipmentId: shipment.id,
        fullExchangePallets: shipment.fullExchangePallets.quantity != null ? shipment.fullExchangePallets : null,
        halfExchangePallets: shipment.halfExchangePallets.quantity != null ? shipment.halfExchangePallets : null,
        quarterExchangePallets: shipment.quarterExchangePallets.quantity != null ? shipment.quarterExchangePallets : null,
      },
    };
    return this.httpClient.post(`${environment.apiUrl}/shipments/updateExchangePallets`, updateCommand);
  }

  updateShipmentDangerousGoods(shipment: any) {
    if (shipment.dangerousGoods && shipment.dangerousGoods.length > 0) {
      // Fix model and remove this code
      for (let i = 0; i < shipment.dangerousGoods.length; i++) {
        if (shipment.dangerousGoods[i].nameAndDescription) {
          shipment.dangerousGoods[i].name = shipment.dangerousGoods[i].nameAndDescription;
        } else {
          shipment.dangerousGoods[i].name = '';
        }
      }
    }
    let updateDangerousGoodsCommand = {
      updateShipmentDangerousGoods: {
        shipmentId: shipment.id,
        dangerousGoods: shipment.dangerousGoods,
      },
    };
    return this.httpClient.post(`${environment.apiUrl}/shipments/updateDangerousGoods`, updateDangerousGoodsCommand);
  }

  updateShipmentServices(shipment: any) {
    var updateShipmentServicesCommand = {
      updateShipmentServices: {
        shipmentId: shipment.id,
        services: shipment.services,
      },
    };
    return this.httpClient.post(`${environment.apiUrl}/shipments/updateServices`, updateShipmentServicesCommand);
  }

  updateShipmentDates(shipment: any) {
    this.sanitizeTimeOnlyFields(shipment);
    var updateShipmentDateCommand = {
      updateShipmentDates: {
        shipmentId: shipment.id,
        pickupDate: formatDate(shipment.pickupDate, 'yyyy-MM-dd', 'da-DK'),
        deliveryDate: formatDate(shipment.deliveryDate, 'yyyy-MM-dd', 'da-DK'),
        pickupTimeStart: shipment.pickupTimeStart,
        pickupTimeEnd: shipment.pickupTimeEnd,
        deliveryTimeStart: shipment.deliveryTimeStart,
        deliveryTimeEnd: shipment.deliveryTimeEnd,
      },
    };
    return this.httpClient.post(`${environment.apiUrl}/e/shipments/updateDates`, updateShipmentDateCommand);
  }

  updateShipmentPickupAndDeliveryDates(shipmentId: any, pickupDate: any, deliveryDate: any) {
    var updateShipmentDateCommand = {
      updateShipmentDates: {
        shipmentId: shipmentId,
        pickupDate: pickupDate,
        deliveryDate: deliveryDate,
      },
    };
    return this.httpClient.post(`${environment.apiUrl}/e/shipments/updateDates`, updateShipmentDateCommand);
  }

  private sanitizeTimeOnlyFields(shipment: any) {
    const timeFields = ['pickupTimeStart', 'pickupTimeEnd', 'deliveryTimeStart', 'deliveryTimeEnd'];
    timeFields.forEach((field) => {
      if (shipment[field] && shipment[field].includes('.')) {
        shipment[field] = shipment[field].replace('.', ':');
      }
      if (shipment[field] && shipment[field].length === 5) {
        shipment[field] = `${shipment[field]}:00`;
      }
    });
  }

  private fetchTodaysShipments(): Observable<SimpleShipment[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('date', new Date().toISOString());
    queryParams = queryParams.append('status', 'Ready');
    return this.httpClient.get<SimpleShipment[]>(`${environment.apiUrl}/shipments/simple`, { params: queryParams });
  }

  private setupTodaysShipmentsSignalR() {
    const connection = this.ensureHubConnection();

    connection.on('shipment-ready', () => {
      this.refreshTodaysShipments.next();
    });
  }

  private setupNotSubmittedShipmentsSignalR() {
    const connection = this.ensureHubConnection();

    connection.on('shipment-created', () => {
      this.refreshNotSubmittedShipments.next();
    });
    connection.on('shipment-ready', () => {
      this.refreshNotSubmittedShipments.next();
    });
  }

  getTodaysShipments() {
    return this.todaysShipmentsCache.asObservable();
  }

  private fetchNotSubmittedShipments(): Observable<SimpleShipment[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('status', 'Booked');

    return this.httpClient.get<SimpleShipment[]>(`${environment.apiUrl}/shipments/simple`, { params: queryParams });
  }

  getNotSubmittedShipments() {
    return this.notSubmittedShipmentsCache.asObservable();
  }

  forceRefreshShipmentsCache() {
    this.refreshNotSubmittedShipments.next();
    this.refreshTodaysShipments.next();
  }

  deleteShipment(shipmentId: any) {
    let deleteShipmentCommand = {
      shipmentId: shipmentId,
      newStatus: 'Deleted',
    };
    return this.httpClient.post(`${environment.apiUrl}/shipments/updateStatus`, deleteShipmentCommand);
  }

  markAsReady(shipmentId, message) {
    return this.httpClient.post(`${environment.apiUrl}/e/shipments/ready`, {
      shipmentId,
      message,
    });
  }

  getFullShipment(shipmentId) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('shipmentId', shipmentId);
    return this.httpClient.get(`${environment.apiUrl}/shipments/full`, {
      params: queryParams,
    });
  }

  createShipment(shipment: Shipment) {
    return new Observable((subscriber) => {
      if (shipment.senderAddress && shipment.senderAddress.date) {
        var dateArray = shipment.senderAddress.date.toString().split('/');
        var convertedDate = new Date(dateArray[1] + '/' + dateArray[0] + '/' + dateArray[2]);
        shipment.pickupDate = formatDate(convertedDate, 'yyyy-MM-dd', 'en-US');
        if (shipment.senderAddress.timeStart) {
          shipment.pickupTimeStart = shipment.senderAddress.timeStart;
        }
        if (shipment.senderAddress.timeEnd) {
          shipment.pickupTimeEnd = shipment.senderAddress.timeEnd;
        }
      }

      if (shipment.recipientAddress && shipment.recipientAddress.date) {
        var dateArray = shipment.recipientAddress.date.toString().split('/');
        var convertedDate = new Date(dateArray[1] + '/' + dateArray[0] + '/' + dateArray[2]);
        shipment.deliveryDate = formatDate(convertedDate, 'yyyy-MM-dd', 'en-US');
        if (shipment.recipientAddress.timeStart) {
          shipment.deliveryTimeStart = shipment.recipientAddress.timeStart;
        }
        if (shipment.recipientAddress.timeEnd) {
          shipment.deliveryTimeEnd = shipment.recipientAddress.timeEnd;
        }
      }
      this.sanitizeTimeOnlyFields(shipment);

      this.hubConnection.on('booking-success', (msg) => {
        subscriber.next(msg);
        subscriber.complete();
        this.refreshTodaysShipments.next();
      });

      this.hubConnection.on('booking-failure', (msg) => {
        console.error(msg);
        subscriber.error(msg);
        subscriber.complete();
      });

      this.hubConnection.invoke('BookShipment', shipment).catch((err) => {
        console.error(err);
        subscriber.error(err);
        subscriber.complete();
      });
    });
  }

  archiveSearch(query: ShipmentArchiveQuery) {
    let queryParams = new HttpParams();
    if (query.fromDate && typeof query.fromDate === 'string') {
      const dateArray = query.fromDate.split('/');
      const convertedDate = new Date(`${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`);
      query.fromDate = this.formatDateToString(convertedDate);
    }

    if (query.toDate && typeof query.toDate === 'string') {
      const dateArray = query.toDate.split('/');
      const convertedDate = new Date(`${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`);
      query.toDate = this.formatDateToString(convertedDate);
    }

    if (query.deliveryDate && typeof query.deliveryDate === 'string') {
      const dateArray = query.deliveryDate.split('/');
      const convertedDate = new Date(`${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`);
      query.deliveryDate = this.formatDateToString(convertedDate);
    }
    Object.entries(query).forEach(([key, value]) => {
      if (value) {
        queryParams = queryParams.append(key, value);
      }
    });

    return this.httpClient.get(`${environment.apiUrl}/shipments/archive`, {
      params: queryParams,
    });
  }

  formatDateToString(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  formatDateStringToDate(date: any): string {
    const dateArray = date.split('/');
    const convertedDate = new Date(dateArray[1] + '/' + dateArray[0] + '/' + dateArray[2]);
    return formatDate(convertedDate, 'yyyy-MM-dd', 'en-US');
  }

  calculatePrice(shipment: Shipment) {
    const postShipment = {
      deliveryDate: this.formatDateStringToDate(shipment.recipientAddress.date),
      pickupDate: this.formatDateStringToDate(shipment.senderAddress.date),
      pickupTimeStart: shipment.senderAddress.timeStart,
      pickupTimeEnd: shipment.senderAddress.timeEnd,
      deliveryTimeStart: shipment.recipientAddress.timeStart,
      deliveryTimeEnd: shipment.recipientAddress.timeEnd,
      services: shipment.services,
      bookingDate: new Date(),
      collis: shipment.collis.map((colli) => ({
        weight: colli.weight ?? 0,
        height: colli.height ?? 0,
        length: colli.length ?? 0,
        width: colli.width ?? 0,
        loadMeter: colli.loadMeter ?? 0,
        type: colli.type,
        colliTypeRef: colli.colliTypeRef,
      })),
      pickupAddress: {
        name: shipment.senderAddress.name,
        zipCode: shipment.senderAddress.zipCode,
        address: shipment.senderAddress.street,
        countryCode: shipment.senderAddress.countryCode,
      },
      deliveryAddress: {
        name: shipment.recipientAddress.name,
        zipCode: shipment.recipientAddress.zipCode,
        address: shipment.recipientAddress.street,
        countryCode: shipment.recipientAddress.countryCode,
      },
    };
    return this.httpClient.post(`${environment.apiUrl}/e/shipments/price`, postShipment);
  }
}

export interface ICreateShipmentResponse {
  trackingNumber: string;
}

export interface ShipmentArchiveQuery {
  barcode?: string;
  waybillNo?: string;
  trackAndTrace?: string;
  reference1?: string;
  reference2?: string;
  reference3?: string;
  fromDate?: string;
  toDate?: string;
  service?: string;
  senderName?: string;
  senderZip?: string;
  recipientName?: string;
  recipientZip?: string;
  deliveryDate?: string;
  note?: string;
}
