import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/notFound/app.notfound.component';
import { ArchiveComponent } from './pages/archive/archive.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { AuthGuard } from './services/auth.guard';
import { PermissionGuard } from './services/permission.guard';
import { OBPermissions } from '@alicetms/auth.permissions';
import { EdiComponent } from './pages/edi/edi.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppMainComponent,
          canActivate: [AuthGuard],
          children: [
            {
              path: '',
              loadChildren: () => import('./pages/frontpage/frontpage.module').then((m) => m.FrontpageModule),
            },
            {
              path: 'booking',
              loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
            {
              path: 'shipments',
              loadChildren: () => import('./pages/shipment/shipment.module').then((m) => m.ShipmentsModule),
            },
            { path: 'archive', component: ArchiveComponent },
            {
              path: 'customers',
              component: CustomersComponent,
              canActivate: [PermissionGuard],
              data: { permission: OBPermissions.ShowCustomers },
            },
            {
              path: 'products',
              loadChildren: () => import('./pages/product-catalog/product-catalog.module').then((m) => m.ProductCatalogModule),
            },
            {
              path: 'edi',
              component: EdiComponent,
              canActivate: [PermissionGuard],
              data: { permission: OBPermissions.UploadEDIFiles },
            },
            {
              path: 'my',
              loadChildren: () => import('./pages/my-page/my-page.module').then((m) => m.MyPageModule),
            },
          ],
        },
        { path: 'notfound', component: AppNotfoundComponent },
        { path: 'forbidden', loadChildren: () => import('./pages/accessdenied/accessdenied.module').then((m) => m.AccessdeniedModule) },
        { path: '**', redirectTo: '/notfound' },
      ],
      {
        scrollPositionRestoration: 'enabled',
        useHash: false,
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
