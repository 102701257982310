import { Injectable } from '@angular/core';
import { Agreement } from '../domain/Agreement';
import { Service } from '../domain/Service';
import { Account } from '../domain/Account';
import { Payer } from '../domain/Payer';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  clientTenant: any;
  clientTenantName: any;
  clientTenantLogo: any;
  clientPermissions: any[] = [];
  clientAgreements: Agreement[] = [];
  clientServices: Service[] = [];
  clientAccounts: Account[] = [];
  clientAddress?: any;
  clientPayers?: Payer[] = [];
  clientReadyDeadline: any;
  countries: [];

  defaultClientPhone?: string;
  defaultClientEmail?: string;
  defaultClientName?: string;
  defaultClientNumber?: string;
  defaultClientContactPerson?: string;
  clientName?: string;
  clientId?: string;

  constructor(private readonly _http: HttpClient) {}

  hasClientPermission(permission: any) {
    return this.clientPermissions.includes(permission);
  }

  getInvoices() {
    return this._http.get('/api/e/invoices');
  }

  getInvoiceDocument(invoiceId: string) {
    return this._http.get(`/api/e/invoices/${invoiceId}/document`).pipe(
      map((res: any) => {
        return res.uri;
      })
    );
  }
}
