import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppBreadcrumbService } from '../../services/app.breadcrumb.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { ConfigService } from 'src/app/services/app.config.service';
import { AppConfig } from 'src/app/domain/appconfig';
import { Shipment } from 'src/app/domain/Shipment';
import { StepperService } from 'src/app/pages/Shared/stepper/stepper.service';
import { ClientService } from 'src/app/services/client.service';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { OBPermissions } from '@alicetms/auth.permissions';
import { AuthService } from 'src/app/services/auth.service';
import * as Leaflet from 'leaflet';
import { DatesService } from 'src/app/services/dates.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  config: AppConfig;
  subscription: Subscription;
  loading: any;

  adrPermission = OBPermissions.BookDangerousGoods;
  BookPickupPermission = OBPermissions.BookPickups;
  BookWithPickupDatePermission = OBPermissions.BookWithPickupDate;
  BookEXW = OBPermissions.BookEXW;
  FrancoPermission = OBPermissions.BookFranco;
  BookGLSPermission = OBPermissions.BookGLS;
  BookPostnordPermission = OBPermissions.BookPostNord;

  enableGLSBooking = false;
  enablePostnordBooking = false;

  newShipment = <Shipment>{
    senderAddress: {},
    recipientAddress: {},
    collis: [],
    dangerousGoods: [],
  };

  // Stepper Variables
  activeIndex: number = 0;
  steps: MenuItem[] = [];

  contextMenuItems: any;

  constructor(
    private breadcrumbService: AppBreadcrumbService,
    public configService: ConfigService,
    public stepperService: StepperService,
    public clientService: ClientService,
    private router: Router,
    public authService: AuthService,
    private datesService: DatesService,
    private translateService: TranslateService
  ) {
    this.breadcrumbService.setItems([
      {
        label: 'Booking',
        command: () => {
          this.router.navigate(['/']);
          this.reset();
        },
      },
      {
        label: this.translateService.instant('NEWBOOKING'),
        command: () => {
          this.reset();
        },
      },
    ]);

    this.config = this.configService.config;
    this.subscription = this.configService.configUpdate$.subscribe((config) => {
      this.config = config;
    });
    this.stepperService.newShipment = this.newShipment;
  }

  ngOnInit() {
    this.buildContextMenu();
    this.stepperService.steps = [];
    if (this.clientService.hasClientPermission(this.BookGLSPermission) || this.authService.hasPermission(this.BookGLSPermission)) {
      this.enableGLSBooking = true;
    }
    if (this.clientService.hasClientPermission(this.BookPostnordPermission) || this.authService.hasPermission(this.BookPostnordPermission)) {
      this.enablePostnordBooking = true;
    }
  }

  reset() {
    this.stepperService.bookingChoice = null;
    this.stepperService.steps = [];
    this.steps = [];
    this.newShipment = <Shipment>{
      senderAddress: {},
      recipientAddress: {},
      collis: [],
      dangerousGoods: [],
    };
    this.stepperService.newShipment = this.newShipment;
  }

  setBookingChoice(choice: any) {
    this.stepperService.bookingChoice = choice;
    this.buildStepperByBookingChoice();
  }

  buildStepperByBookingChoice() {
    switch (this.stepperService.bookingChoice) {
      case 'book':
        this.buildBookStepper();
        break;
      case 'gls':
        this.buildGLS();
        break;
      case 'postnord':
        this.buildPostNord();
        break;
    }
  }

  buildPostNord() {
    this.router.navigate(['booking/postnordBooking']);
  }

  buildGLS() {
    this.router.navigate(['booking/glsBooking']);
  }

  buildBookStepper() {
    this.steps = [];
    if (this.authService.hasPermission(this.BookPickupPermission) || this.authService.hasPermission(this.BookWithPickupDatePermission)) {
      this.steps.push({ label: this.translateService.instant('SENDER'), routerLink: 'booking/sender' });
    }

    if (this.clientService.clientAddress) {
      this.newShipment.senderAddress = this.clientService.clientAddress;
      this.newShipment.senderAddress.name = this.clientService.defaultClientName;
      this.newShipment.senderAddress.contactEmail = this.clientService.defaultClientEmail;
      this.newShipment.senderAddress.contactPhone = this.clientService.defaultClientPhone;
      this.newShipment.senderAddress.contactPerson = this.clientService.defaultClientContactPerson;
    }
    if (!this.newShipment.senderAddress.date) {
      var today = new Date();
      var unFormattedDate = new Date(today.setDate(today.getDate()));
      var formattedDate = formatDate(unFormattedDate, 'dd/MM/yyyy', 'da-DK');
      var isBusinessDay = this.datesService.isBusinessDayOrHoliday(this.datesService.parseDateArrayToDate(formattedDate.split('/')), this.newShipment.senderAddress.countryCode);
      if (isBusinessDay) {
        if (this.newShipment.senderAddress) {
          this.newShipment.senderAddress.date = formattedDate;
        }
      } else {
        var nextBusinessDay = new Date(unFormattedDate);
        while (this.datesService.isBusinessDayOrHoliday(nextBusinessDay, this.newShipment.senderAddress.countryCode) == false) {
          nextBusinessDay.setDate(nextBusinessDay.getDate() + 1);
        }
        var formattedNextBusinessDay = formatDate(nextBusinessDay, 'dd/MM/yyyy', 'da-DK');
        if (this.newShipment.senderAddress) {
          this.newShipment.senderAddress.date = formattedNextBusinessDay;
        }
      }
    }
    this.steps.push({ label: this.translateService.instant('RECIPIENT'), routerLink: 'booking/recipient', disabled: true });

    if (this.authService.hasPermission(this.adrPermission)) {
      this.steps.push({ label: this.translateService.instant('ADR'), routerLink: 'booking/dangerousGoods', disabled: true });
    }

    this.steps.push({ label: this.translateService.instant('COLLI'), routerLink: 'booking/collis', disabled: true });
    if(this.authService.hasSetting('palletexchange.enabled')){
      this.steps.push({ label: this.translateService.instant('EXCHANGEPALLET'), routerLink: 'booking/exchangePallets', disabled: true });
    }
    if (this.clientService.clientServices && this.clientService.clientServices.length > 0) {
      this.steps.push({ label: this.translateService.instant('SERVICE'), routerLink: 'booking/services', disabled: true });
    }
    if (this.clientService.clientAccounts && this.clientService.clientAccounts.length > 0) {
      this.steps.push({ label: this.translateService.instant('ACCOUNTCHOICE'), routerLink: 'booking/account', disabled: true });
      if (this.clientService.clientAccounts.length === 1) {
        // Set Account if only one
        this.newShipment.accountId = this.clientService.clientAccounts[0].id;
        this.newShipment.reference2 = this.clientService.clientAccounts[0].name;
      }
    }

    if (this.clientService.hasClientPermission(this.BookEXW)) {
      if (this.clientService.clientPayers && this.clientService.clientPayers.length > 0) {
        this.steps.push({ label: this.translateService.instant('PAYER'), routerLink: 'booking/payer', disabled: true });
      }
    }

    this.steps.push({ label: this.translateService.instant('NOTES'), routerLink: 'booking/note', disabled: true });

    this.router.navigate([this.steps[0].routerLink]);

    this.stepperService.steps = this.steps.map((step, index) => ({
      label: step.label,
      disabled: step.disabled,
      command: () => {
        this.stepperService.activeIndex = index;
        this.router.navigate([step.routerLink]);
      },
    }));

    this.stepperService.goToStart();
  }

  buildContextMenu() {
    this.contextMenuItems = [
      {
        label: this.translateService.instant('NEWBOOKING'),
        icon: 'pi pi-reset',
        command: () => {
          this.reset();
        },
      },
    ];
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

export const getLayers = (): Leaflet.Layer[] => {
  return [
    new Leaflet.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors',
    } as Leaflet.TileLayerOptions),
  ] as Leaflet.Layer[];
};
