import { Component, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { AppConfig } from 'src/app/domain/appconfig';
import { Shipment } from 'src/app/domain/Shipment';
import { ConfigService } from 'src/app/services/app.config.service';
import { AuthService } from 'src/app/services/auth.service';
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  templateUrl: './booking-preview.component.html',
  selector: 'app-booking-preview',
  styleUrls: ['./booking-preview.component.scss'],
})
export class BookingPreviewComponent implements OnDestroy, AfterViewInit {
  @Input() shipment = <Shipment>{};
  @Input() senderCustomer: any | undefined;
  @Input() recipientCustomer: any | undefined;

  deepEqual = require('deep-equal');

  config: AppConfig;
  subscription: Subscription;
  shipmentSubscription: Subscription;
  bookingType: any[];

  enableShipmentPrice: boolean = false;
  shipmentPrice: any;
  loadingPrice: boolean = true;

  constructor(public configService: ConfigService, private readonly shipmentService: ShipmentService, private readonly auth: AuthService) {
    this.config = this.configService.config;
    this.subscription = this.configService.configUpdate$.subscribe((config) => {
      this.config = config;
    });
    if (auth.hasFeature('onlinebook_showshipmentprice')) {
      this.enableShipmentPrice = true;
    }
  }

  private shipmentCache: any = {};

  ngAfterViewInit() {
    if (this.enableShipmentPrice) {
      this.shipmentSubscription = interval(1000).subscribe(() => {
        this.checkForChanges(JSON.parse(JSON.stringify(this.shipment)));
      });
    }
  }

  private checkForChanges(obj1: any) {
    if (!this.deepEqual(obj1, this.shipmentCache)) {
      this.shipmentCache = JSON.parse(JSON.stringify(this.shipment));
      if (this.shouldGetPrice()) {
        this.loadingPrice = true;
        this.shipmentService.calculatePrice(this.shipment).subscribe((res: any) => {
          this.shipmentPrice = res;
          this.loadingPrice = false;
        });
      }
    }
  }

  private shouldGetPrice() {
    if (
      this.shipment.collis &&
      this.shipment.collis.length > 0 &&
      this.shipment.senderAddress.date &&
      this.shipment.recipientAddress.date &&
      this.shipment.senderAddress.name &&
      this.shipment.senderAddress.zipCode &&
      this.shipment.senderAddress.street &&
      this.shipment.recipientAddress.name &&
      this.shipment.recipientAddress.zipCode &&
      this.shipment.recipientAddress.street
    ) {
      return true;
    }
    return false;
  }

  getToday() {
    return new Date();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.shipmentSubscription) {
      this.shipmentSubscription.unsubscribe();
    }
  }

  deleteColli(colli: any) {
    for (let i = 0; i < this.shipment.collis.length; i++) {
      if (this.shipment.collis[i] === colli) {
        this.shipment.collis.splice(i, 1);
      }
    }
  }
}
