import { Component, OnInit, OnDestroy, Input, ViewChild, AfterViewInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { Agreement } from 'src/app/domain/Agreement';
import { AppConfig } from 'src/app/domain/appconfig';
import { ConfigService } from 'src/app/services/app.config.service';
import { ClientService } from 'src/app/services/client.service';
import { StepperService } from 'src/app/pages/Shared/stepper/stepper.service';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductCatalogService } from 'src/app/services/product-catalog.service';

@Component({
  templateUrl: './colli.component.html',
  selector: 'app-colli',
  styleUrls: ['./colli.component.scss'],
})
export class ColliComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() collies: any[] = [];
  @Input() editColli?: any;
  @Input() removeBtns: boolean = false;
  @ViewChild('quantity') quantityField: any;
  @ViewChild('nextBtn') nextBtn: any;
  @ViewChild('weight') weightField: any;

  config: AppConfig;
  subscription: Subscription;
  colli: any = {};
  agreements: Agreement[] = [];
  lastChosenType: any;

  products: any[] = [];

  ldmTypeValue: any;
  bookVolumeCalculation: string = 'book.volumecalculation';
  bookLdmCalculation: string = 'book.ldmcalculation';

  enableProductCatalog = this.authService.hasSetting('feat.itemcatalog');

  colliForm = new FormGroup({
    quantity: new FormControl(this.colli.quantity, Validators.required),
    type: new FormControl(this.colli.type, Validators.required),
    description: new FormControl({ value: this.colli.description, disabled: true }, Validators.required),
    length: new FormControl({ value: this.colli.length, disabled: true }),
    width: new FormControl({ value: this.colli.width, disabled: true }),
    height: new FormControl({ value: this.colli.height, disabled: true }),
    volume: new FormControl({ value: this.colli.volume, disabled: true }),
    loadMeter: new FormControl({ value: this.colli.loadMeter, disabled: true }),
    weight: new FormControl({ value: this.colli.weight, disabled: true }),
  });

  constructor(
    public configService: ConfigService,
    private messageService: MessageService,
    public stepperService: StepperService,
    private clientService: ClientService,
    public authService: AuthService,
    private translateService: TranslateService,
    private readonly productService: ProductCatalogService
  ) {
    this.config = this.configService.config;
    this.subscription = this.configService.configUpdate$.subscribe((config) => {
      this.config = config;
    });

    this.agreements = this.clientService.clientAgreements;

    this.colliForm.valueChanges.subscribe((data) => {
      if (data.quantity != this.colli.quantity) {
        if (data.length && data.width) {
          if (!this.colliForm.get('length').hasError('max') && !this.colliForm.get('width').hasError('max') && !this.colliForm.get('height').hasError('max')) {
            if (this.authService.hasSetting(this.bookLdmCalculation)) {
              this.colli.loadMeter = (data.length * data.width) / 2.4;
              this.colli.loadMeter = (Math.round(this.colli.loadMeter?.toFixed(2) * 1e2) / 1e2) * data.quantity;
              this.colliForm.patchValue({ loadMeter: this.colli.loadMeter }, { emitEvent: false });
            }
          }
        }
      }
      this.colli.quantity = data.quantity;
      if (data.type) {
        this.colli.type = data.type.colliName;
      }
      this.colli.description = data.description;
      if (data.length <= 20) {
        this.colli.length = data.length;
      } else {
        if (data.length) {
          this.colliForm.get('length').setErrors({ max: true }, { emitEvent: false });
        }
      }

      if (data.width <= 4) {
        this.colli.width = data.width;
      } else {
        if (data.width) {
          this.colliForm.get('width').setErrors({ max: true }, { emitEvent: false });
        }
      }

      if (data.height <= 4) {
        this.colli.height = data.height;
      } else {
        if (data.height) {
          this.colliForm.get('height').setErrors({ max: true }, { emitEvent: false });
        }
      }

      this.colli.volume = data.volume;
      if (data.length && data.width && data.height && !data.volume) {
        if (!this.colliForm.get('length').hasError('max') && !this.colliForm.get('width').hasError('max') && !this.colliForm.get('height').hasError('max')) {
          if (this.authService.hasSetting(this.bookVolumeCalculation)) {
            this.colli.volume = data.length * data.width * data.height * data.quantity;
            this.colliForm.patchValue({ volume: this.colli.volume.toFixed(2) }, { emitEvent: false });
          }
        }
      }

      this.colli.loadMeter = data.loadMeter;
      if (data.length && data.width && !data.loadMeter) {
        if (!this.colliForm.get('length').hasError('max') && !this.colliForm.get('width').hasError('max') && !this.colliForm.get('height').hasError('max')) {
          if (this.authService.hasSetting(this.bookLdmCalculation)) {
            this.colli.loadMeter = (data.length * data.width) / 2.4;
            this.colli.loadMeter = (Math.round(this.colli.loadMeter?.toFixed(2) * 1e2) / 1e2) * data.quantity;
            this.colliForm.patchValue({ loadMeter: this.colli.loadMeter }, { emitEvent: false });
          }
        }
      }
      this.colli.weight = data.weight;
      if (this.editColli) {
        this.editColli = this.colli;
      }
    });

    this.collies = this.stepperService.newShipment.collis;

    productService.getAll().subscribe((res) => {
      this.products = res;
    });

    this.setFormByAgreement();
  }

  ngOnInit(): void {
    if (this.editColli) {
      this.colli = this.editColli;
      this.colliForm.patchValue({
        quantity: this.colli.quantity != null ? this.colli.quantity : 1,
        type: this.clientService.clientAgreements.find((type) => type.colliName === this.colli.type),
        description: this.colli.description,
        length: this.colli.length != null ? Math.round(this.colli.length * 1e2) / 1e2 : null,
        width: this.colli.width != null ? Math.round(this.colli.width?.toFixed(2) * 1e2) / 1e2 : null,
        height: this.colli.height != null ? Math.round(this.colli.height?.toFixed(2) * 1e2) / 1e2 : null,
        volume: this.colli.volume != null ? Math.round(this.colli.volume?.toFixed(2) * 1e2) / 1e2 : null,
        loadMeter: this.colli.loadMeter != null ? Math.round(this.colli.loadMeter?.toFixed(2) * 1e2) / 1e2 : null,
        weight: this.colli.weight != null ? Math.round(this.colli.weight * 1e2) / 1e2 : null,
      });
    }
  }

  isRequiredField(field: string) {
    const form_field = this.colliForm.get(field);
    if (!form_field.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return validator && validator.required;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // Hack to get it focused without errors
      this.focusQuantityField();
    }, 0);
  }

  formattedColliName(item: Agreement) {
    if (item) {
      return `${item.colliName} (${item.name})`;
    }
    return '';
  }

  setFormByAgreement() {
    const typeChanges$ = this.colliForm.get('type').valueChanges.pipe(distinctUntilChanged());

    typeChanges$.subscribe((type) => {
      if (type) {
        this.colliForm.get('description').patchValue(null);
        this.colliForm.get('length').clearValidators();
        this.colliForm.get('length').patchValue(null);
        this.colliForm.get('width').clearValidators();
        this.colliForm.get('width').patchValue(null);
        this.colliForm.get('height').clearValidators();
        this.colliForm.get('height').patchValue(null);
        this.colliForm.get('volume').clearValidators();
        this.colliForm.get('volume').patchValue(null);
        this.colliForm.get('loadMeter').clearValidators();
        this.colliForm.get('loadMeter').patchValue(null);
        this.colliForm.get('weight').clearValidators();
        this.colliForm.get('weight').patchValue(null);

        this.colliForm.enable({ emitEvent: false });

        if (type.name) {
          if (this.checkSpecificClientHackMethodPleaseRemoveASAP()) {
            this.colli.description = type.name;
            this.colliForm.patchValue({ description: this.colli.description });
          }
        }

        if (type.text) {
          this.setFormFieldToRequired('description');
        }

        if (type.length) {
          this.setFormFieldToRequired('length');
        }

        if (type.width) {
          this.setFormFieldToRequired('width');
        }

        if (type.height) {
          this.setFormFieldToRequired('height');
        }

        if (type.volume) {
          this.setFormFieldToRequired('volume');
        }

        if (type.ldmValue) {
          this.ldmTypeValue = type.ldmValue * this.colli.quantity;
          this.colli.loadMeter = type.ldmValue * this.colli.quantity;
          this.colliForm.patchValue({ loadMeter: this.colli.loadMeter }, { emitEvent: false });
        }
        if (type.ldm) {
          this.setFormFieldToRequired('loadMeter');
        }

        if (type.weight) {
          this.setFormFieldToRequired('weight');
        }
        this.colli.typeCode = type.code;
      }
    });
  }

  // TODO : ↓ Remove this when implemented correctly ↓
  checkSpecificClientHackMethodPleaseRemoveASAP() {
    if (this.clientService.clientTenantName == 'tjw' && this.clientService.defaultClientNumber == '43964246') {
      return true;
    }
    return false;
  }

  setFormFieldToRequired(field: string) {
    this.colliForm.get(field).setValidators(Validators.required);
    this.colliForm.get(field).updateValueAndValidity({ emitEvent: false });
  }

  focusHeightLost() {
    if (this.colli.length && this.colli.width && this.colli.height && this.colli.volume && this.colli.loadMeter && !this.colli.weight) {
      this.weightField.input.nativeElement.focus();
    }
  }

  checkNextBtn() {
    if (this.collies.length > 0) {
      return false;
    } else if (this.colliForm.valid) {
      return false;
    }
    return true;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onEnter() {
    if (this.colliForm.valid) {
      this.saveColli();
    } else {
      this.messageService.add({
        severity: 'info',
        summary: this.translateService.instant('MESSAGES.FILLMISSINGFIELDS'),
        detail: this.translateService.instant('MESSAGES.FILLMISSINGIFIELDSANDTRYAGAIN'),
      });
    }
  }

  addColli() {
    if (this.colliForm.valid) {
      this.saveColli();
    }
  }

  saveColli() {
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('MESSAGES.COLLIADDEDTOBOOKING'),
      detail: this.translateService.instant('ADDED') + this.colli.quantity + ' ' + this.colli.type,
    });
    this.collies.push(this.colli);
    this.colli = {};
    this.colliForm.reset();
    this.colliForm.disable();
    this.colliForm.get('quantity').enable();
    this.colliForm.get('type').enable();
    this.colliForm.get('length').clearValidators();
    this.colliForm.get('width').clearValidators();
    this.colliForm.get('height').clearValidators();
    this.colliForm.get('volume').clearValidators();
    this.colliForm.get('loadMeter').clearValidators();
    this.colliForm.get('weight').clearValidators();

    this.ldmTypeValue = null;
    this.focusQuantityField();
  }

  focusQuantityField() {
    this.quantityField.input.nativeElement.focus();
  }

  applyProduct(product: any) {
    let quantity = this.colli.quantity;
    if(!quantity){
        quantity = 1;
    }
    product.collis.forEach((colli) => {
      this.colli = {
        quantity: quantity,
        type: colli.type,
        description: colli.description,
        weight: colli.weight,
        length: colli.length,
        width: colli.width,
        height: colli.height,
        volume: colli.volume,
        loadMeter: colli.loadMeter,
      };
      this.saveColli();
    });
  }
}
