import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ColliComponent } from './colli.component';
import { TooltipModule } from 'primeng/tooltip';
import { FormErrorModule } from 'src/app/pages/error/form-error/form-error.module';
import { TableModule } from 'primeng/table';

@NgModule({
  imports: [
    CommonModule,
    AutoCompleteModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    DividerModule,
    CheckboxModule,
    ButtonModule,
    InputMaskModule,
    InputTextModule,
    InputNumberModule,
    InputTextareaModule,
    TooltipModule,
    FormErrorModule,
    TranslateModule.forChild({
      extend: true,
    }),
    TableModule,
  ],
  declarations: [ColliComponent],
  exports: [ColliComponent],
})
export class ColliModule {}
