import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductCatalogService {
  constructor(private readonly _http: HttpClient) {}

  public getAll(): Observable<any> {
    return this._http.get<any>(`/api/product-catalog`);
  }

  public get(id: string): Observable<any> {
    return this._http.get<any>(`/api/product-catalog/${id}`);
  }

  public create(data: any): Observable<any> {
    return this._http.post<any>(`/api/product-catalog`, data);
  }

  public update(id: string, data: any): Observable<any> {
    return this._http.put<any>(`/api/product-catalog/${id}`, data);
  }

  public delete(id: string): Observable<any> {
    return this._http.delete<any>(`/api/product-catalog/${id}`);
  }
}
