import { Component } from '@angular/core';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AuthService } from './services/auth.service';
import { ClientService } from './services/client.service';

@Component({
  selector: 'app-topbar',
  template: `
    <div class="layout-topbar">
      <div class="layout-topbar-left">
        <a href="#" class="topbar-menu-button" (click)="appMain.onMenuButtonClick($event)" *ngIf="appMain.isOverlay() || appMain.isMobile()">
          <i class="pi pi-bars"></i>
        </a>

        <a routerLink="/" class="logo">
          <img *ngIf="!clientService.clientTenantLogo" [src]="'assets/layout/images/OnlineBook/ob_logo_' + (app.colorScheme === 'light' ? 'dark' : 'light') + '.svg'" />
          <img *ngIf="clientService.clientTenantLogo" [src]="clientService.clientTenantLogo" />
        </a>
      </div>

      <app-menu></app-menu>

      <div class="layout-topbar-right">
        <ul class="layout-topbar-right-items">
          <li
            #profile
            class="profile-item"
            [ngClass]="{
              'active-topmenuitem': appMain.activeTopbarItem === profile
            }"
          >
            <a href="#" (click)="appMain.onTopbarItemClick($event, profile)">
              <img src="assets/layout/images/profile-image.png" />
            </a>

            <ul class="fadeInDown">
              <li role="menuitem">
                <a style="cursor: default">
                  <i class="pi pi-fw pi-user"></i>
                  <span>{{ auth.userProfile?.name }}</span>
                </a>
              </li>
              <li role="menuitem">
                <a (click)="auth.logout()" style="cursor: pointer">
                  <i class="pi pi-fw pi-sign-out"></i>
                  <span>{{ 'LOGOUT' | translate }}</span>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <span style="font-weight:500; font-size:small;" *ngIf="clientService.clientName">{{ clientService.clientName }}</span>
          </li>
        </ul>
      </div>
    </div>
  `,
})
export class AppTopbarComponent {
  constructor(public app: AppComponent, public appMain: AppMainComponent, public auth: AuthService, public clientService: ClientService) {}
}
